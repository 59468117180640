import React from 'react';
import './CSS/main.css';
import image from './img/Logo-IHT-2.jpg';
import Header from './header';

function App() {
  return (
    <div className="App">
      <Header />
      <div className='img-container container flex'>
        <img src={image} className='logo-main img' alt='Логотип компании'></img>
      </div>
    </div>
  );
}

export default App;
